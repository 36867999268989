import { axiosClient } from "../axios";

const baseUrl = "/ateco-codes";

export function getList() {
  return axiosClient.get(baseUrl);
}

export function getNaceMatchingList() {
  return axiosClient.get(`${baseUrl}/nace-matching`);
}

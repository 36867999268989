import { EsgCompiledEligibilityTestApi } from "@/api";
import i18n from "@/i18n";
import router from "@/router";
import { showSuccessSnackbar, snackBarMapError } from "@/utilities/snackbar";

export async function getAll(params) {
  const { data } = await EsgCompiledEligibilityTestApi.getAll(params);
  return data;
}

export async function get(id, params) {
  try {
    const { data } = await EsgCompiledEligibilityTestApi.get(id, params);
    return data;
  } catch (ex) {
    snackBarMapError("esg.eligibility-test-not-found");
    if (ex.response.status === 404) {
      router.push({ name: "eligibility-tests" });
    }
  }
}

export async function save(data) {
  const { data: savedData } = await EsgCompiledEligibilityTestApi.save(data);
  showSuccessSnackbar(200, i18n.t("esg.eligibility-test-saved-correctly"));
  return savedData;
}

export async function exportResult(id) {
  try {
    const { data } = await EsgCompiledEligibilityTestApi.exportResult(id);
    return data;
  } catch (ex) {
    snackBarMapError(undefined, i18n.t("something-went-wrong") + ex);
    if (ex.response.status === 404) {
      router.push({ name: "eligibility-tests" });
    }
  }
}

export async function downloadAllCompliantDocumentationFiles(id) {
  let data;
  try {
    ({ data: data } =
      await EsgCompiledEligibilityTestApi.downloadAllCompliantDocumentationFiles(
        id
      ));
  } catch (ex) {
    snackBarMapError(undefined, i18n.t("something-went-wrong") + ex);
  }
  return data;
}

const getDefaultState = () => {
  return {
    eligibilityTest: undefined,
    compiledEligibilityTest: undefined,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getCompiledEligibilityTest: (state) => {
      return state.compiledEligibilityTest;
    },
    getEligibilityTest: (state) => {
      return state.eligibilityTest;
    },
  },
  mutations: {
    SET_COMPILED_ELIGIBILITY_TEST(state, payload) {
      state.compiledEligibilityTest = payload;
    },
    SET_ELIGIBILITY_TEST(state, payload) {
      state.eligibilityTest = payload;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    setCompiledEligibilityTest({ commit }, payload) {
      commit("SET_COMPILED_ELIGIBILITY_TEST", payload);
    },
    setEligibilityTest({ commit }, payload) {
      commit("SET_ELIGIBILITY_TEST", payload);
    },
    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};

import { AtecoApi } from "@/api";

export async function getList() {
  const { data } = await AtecoApi.getList();
  return data;
}

export async function getNaceMatchingList() {
  const { data } = await AtecoApi.getNaceMatchingList();
  return data;
}

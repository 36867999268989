import { axiosClient } from "../axios";
import { getQuery } from "../helper";

const baseUrl = "/api/docs/scenarios";

export function getSchema() {
  return axiosClient.get(`${baseUrl}/schema`);
}

export function get(id) {
  return axiosClient.get(`${baseUrl}/${id}`);
}

export function getBlobByHash(hash) {
  return axiosClient.get(`${baseUrl}/blob/${hash}`);
}

export function save(data) {
  return axiosClient.post(`${baseUrl}/save`, data);
}

export function remove(id) {
  const query = getQuery({ id });
  return axiosClient.post(`${baseUrl}/delete`, {}, query);
}

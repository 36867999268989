import { axiosClient } from "../axios";
import { getQuery } from "../helper";

const baseUrl = "/api/registry";

export function registerEnterprise(data) {
  return axiosClient.post(`${baseUrl}/enterprise/register`, data);
}

export function registerEnterpriseUser(data) {
  return axiosClient.post(`${baseUrl}/enterprise/users/register`, data);
}

export function registerFirmUser(data) {
  return axiosClient.post(`${baseUrl}/accountant/users/register`, data);
}

export function validateEnterpriseEmail(token, newPassword) {
  const query = getQuery({ token });
  return axiosClient.post(
    `${baseUrl}/enterprise/validate`,
    { password: newPassword },
    query
  );
}

export function validateAccountantEmail(token, newPassword) {
  const query = getQuery({ token });
  return axiosClient.post(
    `${baseUrl}/accountant/validate`,
    { password: newPassword },
    query
  );
}

import { FirmApi } from "@/api";
import i18n from "@/i18n";
import { showSuccessSnackbar } from "@/utilities/snackbar";

export async function getAll() {
  const { data } = await FirmApi.getAll();
  return data;
}

export async function get(id) {
  const { data } = await FirmApi.get(id);
  if (data.length === 0) {
    return {};
  }
  return data[0];
}

export async function registration(data) {
  const { status, data: response } = await FirmApi.registration(data);
  showSuccessSnackbar(status, `${i18n.t("firm-created-correctly")}`);
  return response;
}

export async function update(firmId, data) {
  const { data: response } = await FirmApi.update(firmId, data);
  return response;
}

export async function toggleEnabled(data) {
  data.deletedAt =
    data.deletedAt === undefined ? new Date().toISOString() : undefined;
  return this.update(data.id, data);
}

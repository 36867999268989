const getDefaultState = () => {
  return {
    scenarioReadonly: undefined,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getScenarioReadonly: (state) => {
      return state.scenarioReadonly;
    },
  },
  mutations: {
    SET_SCENARIO_READONLY(state, bool) {
      if (bool != undefined) {
        state.scenarioReadonly = bool;
      }
    },
  },
  actions: {
    async setScenarioReadonly({ commit }, payload) {
      commit("SET_SCENARIO_READONLY", payload);
    },
  },
};

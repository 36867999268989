var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":_vm.disabled}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("scenarios-list")))])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"techBlue white--text"},[_vm._v(" "+_vm._s(_vm.$t("scenarios-list"))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":_vm.$t('search'),"solo":"","dense":"","color":"white","hide-details":""},model:{value:(_vm.scenarioTableSearch),callback:function ($$v) {_vm.scenarioTableSearch=$$v},expression:"scenarioTableSearch"}})],1),_c('v-card-text',{staticClass:"pt-5"},[_c('v-data-table',{attrs:{"headers":_vm.scenariosTableHeaders,"items":_vm.scenarios,"search":_vm.scenarioTableSearch},scopedSlots:_vm._u([{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatCreatedAtScenarioDate(item.createdAt))+" ")]}},{key:`item.type`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.scenarioTypeCodeToLabel(item.type))+" ")]}},{key:`item.action`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"icon":"","to":{
                name: 'scenario',
                params: { scenarioId: item.id, readonly: true },
              }}},[_c('v-icon',{attrs:{"color":"techBlueDark"}},[_vm._v("mdi-information-outline")])],1),(_vm.canEdit)?_c('v-btn',{attrs:{"icon":"","to":{
                name: 'scenario',
                params: { scenarioId: item.id, readonly: false },
              }}},[_c('v-icon',{attrs:{"color":"techBlue"}},[_vm._v("mdi-pencil-outline")])],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.canEdit)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-trash-can-outline")])],1):_vm._e()]}}],null,true),model:{value:(_vm.nestedDialog),callback:function ($$v) {_vm.nestedDialog=$$v},expression:"nestedDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("elimination-confirm"))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("delete-scenario-warning"))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"plain":""},on:{"click":function($event){_vm.nestedDialog = false}}},[_vm._v("Chiudi")]),_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.deleteScenario(item.id)}}},[_vm._v(_vm._s(_vm.$t("confirm")))])],1)],1)],1)],1)]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"plain":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.$t("close")))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { axiosClient } from "../axios";
import { getQuery } from "../helper";

const baseUrl = "/api/registry/users";

export function getAllFromFirm(firmId = undefined) {
  const query = getQuery({ firmId });
  return axiosClient.get(`${baseUrl}/firm`, query);
}

export function getByEnterpriseId(enterpriseId) {
  const query = getQuery({ enterpriseId });
  return axiosClient.get(`${baseUrl}/enterprises`, query);
}

export function updateFirmUser(firmId, data) {
  return axiosClient.put(`${baseUrl}/firm/${firmId}`, data);
}

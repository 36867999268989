import i18n from "@/i18n";

export class Formatters {
  static dateFormatterDDMMYYYY(date) {
    if (date == null) {
      return null;
    } else {
      let newDate = new Date(date);

      let dateDay = newDate.getDate().toString();
      let dateMonth = newDate.getMonth() + 1;
      if (dateDay < 10) {
        dateDay = "0" + dateDay.toString();
      } else {
        dateDay = dateDay.toString();
      }
      if (dateMonth < 10) {
        dateMonth = "0" + dateMonth.toString();
      } else {
        dateMonth = dateMonth.toString();
      }
      let dateYear = newDate.getFullYear().toString();

      let dateFormatted = `${dateDay}/${dateMonth}/${dateYear}`;
      return dateFormatted;
    }
  }

  static dateFormatterYYYYMMDD(date) {
    if (date == null) {
      return null;
    } else {
      let newDate = new Date(date);

      let dateDay = newDate.getDate().toString();
      if (dateDay < 10) {
        dateDay = "0" + dateDay.toString();
      } else {
        dateDay = dateDay.toString();
      }
      let dateMonth = newDate.getMonth() + 1;
      if (dateMonth < 10) {
        dateMonth = "0" + dateMonth.toString();
      } else {
        dateMonth = dateMonth.toString();
      }
      let dateYear = newDate.getFullYear().toString();

      let dateFormatted = `${dateYear}-${dateMonth}-${dateDay}`;
      return dateFormatted;
    }
  }

  static stringFormatterYYYYMMGG(dateDDMMYYYY) {
    if (dateDDMMYYYY == null) {
      return null;
    } else {
      let dateSplitted = dateDDMMYYYY.split("/");

      let dateFormatted =
        dateSplitted[2] + "-" + dateSplitted[1] + "-" + dateSplitted[0];
      return dateFormatted;
    }
  }

  static multiDateFormatter(objectsArrayToFormat, singleObjectKeyToFormat) {
    objectsArrayToFormat.forEach((singleObject) => {
      singleObject[singleObjectKeyToFormat] = this.dateFormatterDDMMYYYY(
        singleObject[singleObjectKeyToFormat]
      );
    });
  }

  static currencyFormatter(number) {
    if (number != undefined) {
      let parsedNumber = parseFloat(number);
      return parsedNumber.toFixed(2) + " " + i18n.t("currency-symbol");
    } else {
      return "- - -";
    }
  }

  static formatListForModaleSelect(
    objectsArray,
    stringKeyToValue,
    stringKeyToText
  ) {
    let listForSelect = [];
    for (let index = 0; index < objectsArray.length; index++) {
      const element = {};
      element.value = objectsArray[index][stringKeyToValue];
      element.text = objectsArray[index][stringKeyToText];
      listForSelect.push(element);
    }
    return listForSelect;
  }

  static formatDate(value) {
    var m = new Date(value);
    var dateString =
      m.getFullYear() +
      "/" +
      ("0" + (m.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + m.getDate()).slice(-2);
    return dateString;
  }

  static formatDateAndHour(value) {
    var m = new Date(value);
    var dateString =
      m.getFullYear() +
      "/" +
      ("0" + (m.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + m.getDate()).slice(-2) +
      " " +
      ("0" + m.getHours()).slice(-2) +
      ":" +
      ("0" + m.getMinutes()).slice(-2) +
      ":" +
      ("0" + m.getSeconds()).slice(-2);
    return dateString;
  }
}

import { EsgImportApi } from "@/api";
import i18n from "@/i18n";
import { showSuccessSnackbar } from "@/utilities/snackbar";

export async function upload(formData) {
  await EsgImportApi.upload(formData);
  showSuccessSnackbar(
    200,
    `${formData.get("zipFile").name} ${i18n.t(
      "append-to-filename-saved-correctly"
    )}`
  );
}

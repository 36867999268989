import { axiosClient } from "../axios";
import { getQuery } from "../helper";

const baseUrl = "api/esg/compiled-eligibility-tests";

export function getAll(params) {
  const query = getQuery(params);
  return axiosClient.get(baseUrl, query);
}

export function get(id, params) {
  const query = getQuery(params);
  return axiosClient.get(`${baseUrl}/${id}`, query);
}

export function save(data) {
  return axiosClient.post(baseUrl, data);
}

export function exportResult(id) {
  return axiosClient.get(`${baseUrl}/${id}/result/export`);
}

export function downloadAllCompliantDocumentationFiles(id) {
  const config = {
    responseType: "blob",
  };
  return axiosClient.get(
    `${baseUrl}/${id}/all-compliant-documentation-files/download`,
    config
  );
}

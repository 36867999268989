<template>
  <v-btn
    fab
    fixed
    bottom
    right
    dark
    color="techBlue"
    style="z-index: 100"
    @click="switchFullScreen"
  >
    <v-icon>{{
      isFullScreen ? "mdi-fullscreen-exit" : "mdi-fullscreen"
    }}</v-icon>
  </v-btn>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TheFullScreenBtn",
  computed: {
    ...mapGetters({
      isFullScreen: "shared/getFullScreen",
    }),
  },
  methods: {
    ...mapActions({
      setFullScreen: "shared/setFullScreen",
    }),
    switchFullScreen() {
      this.setFullScreen(!this.isFullScreen);
    },
  },
};
</script>

import { EnterpriseApi } from "@/api";
import i18n from "@/i18n";
import { showErrorSnackbar, showSuccessSnackbar } from "@/utilities/snackbar";

export async function get(id) {
  const { data } = await EnterpriseApi.get(id);
  return data;
}

export async function getByFirm(firmId) {
  const { data } = await EnterpriseApi.getByFirm(firmId);
  return data;
}

export async function clone(enterpriseId, data) {
  try {
    await EnterpriseApi.clone(enterpriseId, data);
  } catch (ex) {
    showErrorSnackbar(undefined, i18n.t("something-went-wrong") + ex);
  }
  showSuccessSnackbar(200, i18n.t("enterprise-cloned-correctly"));
}

export async function update(enterpriseId, data) {
  const { data: response } = await EnterpriseApi.update(enterpriseId, data);
  showSuccessSnackbar(200, i18n.t("enterprise-updated-correctly"));
  return response;
}

export async function exportCompiledEligibilityTests(enterpriseId) {
  try {
    const { data } = await EnterpriseApi.exportCompiledEligibilityTests(
      enterpriseId
    );
    return data;
  } catch (ex) {
    showErrorSnackbar(undefined, i18n.t("something-went-wrong") + ex);
  }
}

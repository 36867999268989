<template>
  <v-menu
    bottom
    offset-y
    z-index="1000"
    :close-on-content-click="false"
    max-width="400"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        outlined
        color="techBlueDark"
        class="mr-2"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t("visualization") }}
        <v-icon right>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-card flat tile>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              dense
              color="techBlue"
              prefix="Zoom:"
              :value="zoomText"
              readonly
              prepend-inner-icon="mdi-minus"
              append-icon="mdi-plus"
              @click:append="zoomIn"
              @click:prepend-inner="zoomOut"
            />
          </v-col>
          <v-col v-if="differenceView" cols="12">
            <v-select
              v-model="diffType"
              dense
              outlined
              :items="differenceTypesList"
              color="techBlue"
              hide-details
              :label="$t('visualize-differences')"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
    <v-list>
      <v-list-item v-if="thousandView" @click="switchThousands">
        <v-list-item-icon>
          <v-icon>{{
            expressInThousands ? "mdi-currency-eur" : "mdi-alpha-k-circle"
          }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{
            expressInThousands
              ? $t("visualize-standard-values")
              : $t("visualize-k-values")
          }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="compactOrIndirectView">
        <v-select
          v-model="view"
          dense
          outlined
          :items="viewTypesList"
          color="techBlue"
          hide-details
          :label="$t('visualization')"
        />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "VisualizationBtnDropdown",
  computed: {
    ...mapGetters({
      zoom: "spreadsheet/getZoom",
      expressInThousands: "spreadsheet/getExpressInThousands",
      storeView: "spreadsheet/getView",
      isCompact: "spreadsheet/getCompactView",
      isIndirect: "spreadsheet/getIndirectView",
      storeDiffType: "spreadsheet/getDifferenceType",
    }),
    zoomText() {
      return this.zoom !== 0 ? `${100 + this.zoom * 10}%` : "100%";
    },
    thousandView() {
      return this.$route.meta.header?.buttons.visualization?.thousands ?? false;
    },
    compactOrIndirectView() {
      return (
        this.$route.meta.header?.buttons.visualization?.compactOrIndirect ??
        false
      );
    },
    differenceView() {
      return (
        this.$route.meta.header?.buttons.visualization?.difference ?? false
      );
    },
    differenceTypesList() {
      return [
        { text: this.$t("no-difference"), value: 1 },
        { text: this.$t("absolute-difference"), value: 2 },
        { text: this.$t("relative-difference"), value: 3 },
        { text: this.$t("original-value"), value: 4 },
      ];
    },
    diffType: {
      get() {
        return this.storeDiffType;
      },
      set(value) {
        this.setDifferenceType(value);
      },
    },
    viewTypesList() {
      return [
        {
          text: this.$t("visualize-direct-values"),
          value: { compactView: false, indirectView: false },
        },
        {
          text: this.$t("visualize-compact-values"),
          value: { compactView: true, indirectView: false },
        },
        {
          text: this.$t("visualize-indirect-values"),
          value: { compactView: false, indirectView: true },
        },
      ];
    },
    view: {
      get() {
        return this.storeView;
      },
      set(value) {
        this.setView(value);
      },
    },
  },
  methods: {
    ...mapActions({
      setZoom: "spreadsheet/setZoom",
      setExpressInThousands: "spreadsheet/setExpressInThousands",
      setView: "spreadsheet/setView",
      setDifferenceType: "spreadsheet/setDifferenceType",
    }),
    zoomIn() {
      if (this.zoom < 5) {
        this.setZoom(this.zoom + 1);
      }
    },
    zoomOut() {
      if (this.zoom > -5) {
        this.setZoom(this.zoom - 1);
      }
    },
    switchThousands() {
      this.setExpressInThousands(!this.expressInThousands);
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" scrollable max-width="1000">
    <template #activator="{ on, attrs }">
      <v-list-item :disabled="disabled" v-bind="attrs" v-on="on">
        <v-list-item-icon>
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("scenarios-list") }}</v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="techBlue white--text">
        {{ $t("scenarios-list") }}
        <v-spacer />
        <v-text-field
          v-model="scenarioTableSearch"
          :label="$t('search')"
          solo
          dense
          color="white"
          hide-details
        />
      </v-card-title>
      <v-card-text class="pt-5">
        <v-data-table
          :headers="scenariosTableHeaders"
          :items="scenarios"
          :search="scenarioTableSearch"
        >
          <template #[`item.createdAt`]="{ item }">
            {{ formatCreatedAtScenarioDate(item.createdAt) }}
          </template>
          <template #[`item.type`]="{ item }">
            {{ scenarioTypeCodeToLabel(item.type) }}
          </template>
          <template #[`item.action`]="{ item }">
            <div class="d-flex justify-end">
              <v-btn
                icon
                :to="{
                  name: 'scenario',
                  params: { scenarioId: item.id, readonly: true },
                }"
              >
                <v-icon color="techBlueDark">mdi-information-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="canEdit"
                icon
                :to="{
                  name: 'scenario',
                  params: { scenarioId: item.id, readonly: false },
                }"
              >
                <v-icon color="techBlue">mdi-pencil-outline</v-icon>
              </v-btn>
              <v-dialog v-model="nestedDialog" max-width="600" persistent>
                <template #activator="{ on, attrs }">
                  <v-btn v-if="canEdit" v-bind="attrs" icon v-on="on">
                    <v-icon color="red">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title> {{ $t("elimination-confirm") }} </v-card-title>
                  <v-card-text>
                    {{ $t("delete-scenario-warning") }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn plain @click="nestedDialog = false">Chiudi</v-btn>
                    <v-btn color="red" dark @click="deleteScenario(item.id)">{{
                      $t("confirm")
                    }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn plain @click="dialog = false">{{ $t("close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Formatters } from "@/utilities/formatters.js";
import { Scenario } from "@/utilities/enums.js";
import { ScenarioService } from "@/services";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    scenarios: {
      type: Array,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      nestedDialog: false,

      scenarioTableSearch: "",
    };
  },
  computed: {
    scenariosTableHeaders() {
      return [
        {
          text: this.$t("name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("creation-date"),
          align: "center",
          sortable: false,
          value: "createdAt",
        },
        {
          text: this.$t("scenario-type"),
          align: "center",
          sortable: false,
          value: "type",
        },
        {
          text: this.$t("actions"),
          align: "end",
          sortable: false,
          value: "action",
        },
      ];
    },
  },
  methods: {
    formatCreatedAtScenarioDate: function (date) {
      return Formatters.formatDate(date);
    },
    scenarioTypeCodeToLabel: function (type) {
      return Scenario.codeToLabel(type);
    },
    async deleteScenario(id) {
      this.nestedDialog = false;
      await ScenarioService.remove(id);
      this.dialog = false;
    },
  },
};
</script>

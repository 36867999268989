<template>
  <v-menu bottom offset-y z-index="1000">
    <template #activator="{ on, attrs }">
      <v-btn color="techBlue" outlined class="mr-2" v-bind="attrs" v-on="on">
        {{ $t("scenarios") }}
        <v-icon right>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list>
      <ScenarioListDialog
        :disabled="scenarios.data.length === 0"
        :scenarios="scenarios.data"
        :can-edit="scenarios.editable"
      />
      <ScenarioCreationDialog
        :disabled="disableAdd"
        :business-plan-id="businessPlanId"
      />
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import ScenarioCreationDialog from "@/components/dialogs/ScenarioCreationDialog.vue";
import ScenarioListDialog from "@/components/dialogs/ScenarioListDialog.vue";

export default {
  name: "ScenarioBtnDropdown",
  components: {
    ScenarioCreationDialog,
    ScenarioListDialog,
  },
  computed: {
    ...mapGetters({
      scenarios: "businessPlan/getScenarios",
      businessPlan: "businessPlan/getBusinessPlan",
      businessPlanReady: "businessPlan/isReady",
    }),
    disableAdd() {
      if (!this.businessPlanReady) return true;
      return !this.scenarios.editable || !this.businessPlan.id;
    },
    businessPlanId() {
      return this.businessPlanReady ? this.businessPlan.id : null;
    },
  },
};
</script>

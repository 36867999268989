import { axiosClient } from "../axios";
import { getQuery } from "../helper";

const baseUrl = "/api/registry/enterprises";

export function get(id) {
  return axiosClient.get(`${baseUrl}/${id}`);
}

export function getByFirm(firmId) {
  const query = getQuery({ firmId });
  return axiosClient.get(baseUrl, query);
}

export function clone(enterpriseId, data) {
  return axiosClient.post(`${baseUrl}/${enterpriseId}/clone`, data);
}

export function update(enterpriseId, data) {
  return axiosClient.put(`${baseUrl}/${enterpriseId}`, data);
}

export function exportCompiledEligibilityTests(enterpriseId) {
  return axiosClient.get(
    `${baseUrl}/${enterpriseId}/compiled-eligibility-tests/export`
  );
}

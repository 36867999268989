const getDefaultState = () => {
  return {
    snackbar: {
      active: false,
      timeout: undefined,
      color: undefined,
      message: undefined,
      code: undefined,
    },
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getSnackbar: (state) => {
      return state.snackbar;
    },
  },
  mutations: {
    SET_SNACKBAR(state, payload) {
      state.snackbar = payload;
    },
  },
  actions: {
    setSnackbar({ commit }, payload) {
      commit("SET_SNACKBAR", {
        active: true,
        timeout: payload.timeout,
        color: payload.color,
        message: payload.message,
        code: payload.code,
      });
    },
  },
};

import { axiosClient } from "../axios";

const baseUrl = "api/esg/import";

export function upload(formData) {
  return axiosClient.post(baseUrl, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    timeout: 600000,
  });
}

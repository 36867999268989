import { axiosClient } from "../axios";
import { getQuery } from "../helper";

const baseUrl = "/api/docs/business-plans";

export function getSchema() {
  return axiosClient.get(`${baseUrl}/schema`);
}

export function getConstraints() {
  return axiosClient.get(`${baseUrl}/constraints`);
}

export function getAddons() {
  return axiosClient.get(`${baseUrl}/addons`);
}

export function getEasyModeTemplate() {
  return axiosClient.get(`${baseUrl}/easy-mode-template`);
}

export function get(id) {
  return axiosClient.get(`${baseUrl}/${id}`);
}

export function getByEnterpriseId(enterpriseId) {
  const query = getQuery({ enterpriseId });
  return axiosClient.get(baseUrl, query);
}

export function getBlobByHash(hash) {
  return axiosClient.get(`${baseUrl}/blob/${hash}`);
}

export function getOriginalWorkbook(scenarioId) {
  return axiosClient.get(`${baseUrl}/original-workbook/${scenarioId}`);
}

export function save(data) {
  return axiosClient.post(`${baseUrl}/save`, data);
}

export function rename(id, name) {
  return axiosClient.post(`${baseUrl}/${id}/rename`, name);
}

export function remove(id) {
  const query = getQuery({ id });
  return axiosClient.post(`${baseUrl}/delete`, {}, query);
}

export function exportToExcel(list) {
  return axiosClient.post(`${baseUrl}/export/excel`, list);
}

export function getJsonData() {
  return axiosClient.get("business-plan-data.json");
}

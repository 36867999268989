import { EsgEligibilityTestApi } from "@/api";

export async function get(params) {
  const { data } = await EsgEligibilityTestApi.get(params);
  return data;
}

export async function getQuestionnaires(eligibilityTestId, params) {
  const { data } = await EsgEligibilityTestApi.getQuestionnaires(
    eligibilityTestId,
    params
  );
  return data;
}

import { BusinessPlanService } from "@/services";

const getDefaultState = () => {
  return {
    businessPlan: undefined,
    editable: false,
    lastSchemaVersion: undefined,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getBusinessPlan: (state) => {
      return state.businessPlan;
    },
    getScenarios: (state) => {
      return {
        data: state.businessPlan?.scenarios || [],
        editable: state.editable,
      };
    },
    getLastSchemaVersion: (state) => {
      return state.lastSchemaVersion;
    },
    isReady: (state) => {
      return state.businessPlan !== undefined;
    },
  },
  mutations: {
    SET_BUSINESS_PLAN(state, businessPlan) {
      state.businessPlan = businessPlan;
    },
    SET_EDITABLE(state, editable) {
      state.editable = editable;
    },
    SET_LAST_SCHEMA_VERSION(state, version) {
      state.lastSchemaVersion = version;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    setBusinessPlan({ commit }, { businessPlan, editable = false }) {
      commit("SET_BUSINESS_PLAN", businessPlan);
      commit("SET_EDITABLE", editable);
    },
    setEditable({ commit }, payload) {
      commit("SET_EDITABLE", payload);
    },
    async fetchLastSchemaVersion({ commit }, version = undefined) {
      if (version === undefined) {
        version = (await BusinessPlanService.getSchema()).version;
      }
      commit("SET_LAST_SCHEMA_VERSION", version);
    },
    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};

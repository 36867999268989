const getDefaultState = () => {
  return {
    zoom: 0,
    expressInThousands: false,
    view: {
      compactView: false,
      indirectView: false,
    },
    tooltip: false,
    readOnly: false,
    differenceType: 1,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getZoom: (state) => {
      return state.zoom;
    },
    getExpressInThousands: (state) => {
      return state.expressInThousands;
    },
    getView: (state) => {
      return state.view;
    },
    getCompactView: (state) => {
      return state.view.compactView;
    },
    getIndirectView: (state) => {
      return state.view.compactView;
    },
    getTooltip: (state) => {
      return state.tooltip;
    },
    getReadOnly: (state) => {
      return state.readOnly;
    },
    getDifferenceType: (state) => {
      return state.differenceType;
    },
  },
  mutations: {
    SET_ZOOM(state, payload) {
      state.zoom = payload;
    },
    SET_EXPRESS_IN_THOUSANDS(state, payload) {
      state.expressInThousands = payload;
    },
    SET_VIEW(state, payload) {
      state.view = payload;
    },
    SET_COMPACT_VIEW(state, payload) {
      state.view.compactView = payload;
    },
    SET_INDIRECT_VIEW(state, payload) {
      state.view.indirectView = payload;
    },
    SET_TOOLTIP(state, payload) {
      state.tooltip = payload;
    },
    SET_READ_ONLY(state, payload) {
      state.readOnly = payload;
    },
    SET_DIFFERENCE_TYPE(state, payload) {
      state.differenceType = payload;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    setZoom({ commit }, payload) {
      commit("SET_ZOOM", payload);
    },
    setExpressInThousands({ commit }, payload) {
      commit("SET_EXPRESS_IN_THOUSANDS", payload);
    },
    setView({ commit }, payload) {
      commit("SET_VIEW", payload);
    },
    setCompactView({ commit }, payload) {
      commit("SET_COMPACT_VIEW", payload);
    },
    setIndirectView({ commit }, payload) {
      commit("SET_INDIRECT_VIEW", payload);
    },
    setTooltip({ commit }, payload) {
      commit("SET_TOOLTIP", payload);
    },
    setReadOnly({ commit }, payload) {
      commit("SET_READ_ONLY", payload);
    },
    setDifferenceType({ commit }, payload) {
      commit("SET_DIFFERENCE_TYPE", payload);
    },
    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};

import i18n from "@/i18n";
import {
  isEmail,
  isInt,
  isMobilePhone,
  isStrongPassword,
  isURL,
} from "validator";

export class Validators {
  static rules = {
    required: (value) => this.requiredValidation(value),
    email: (value) => this.emailValidation(value),
    passwordValidation: (value) => this.passwordValidation(value),
    webSiteUrlValidator: (value) => this.webSiteUrlValidator(value),
    internationalMobileNumber: (value) => this.internationalMobileNumber(value),
    samePasswordValidation: (value, otherPassword) =>
      this.samePasswordValidation(value, otherPassword),
    integerPositiveNumber: (value) => this.integerPositiveNumber(value),
  };

  static requiredValidation(inputToValidate) {
    return (
      (inputToValidate !== null &&
        inputToValidate !== undefined &&
        inputToValidate !== "") ||
      i18n.t("validator-required")
    );
  }

  static passwordValidation(inputToValidate) {
    if (inputToValidate === undefined || inputToValidate.length === 0)
      return true;
    return (
      isStrongPassword(inputToValidate, {
        minLength: 8,
        minLowercase: 0,
        minUppercase: 0,
        minNumbers: 1,
        minSymbols: 1,
        returnScore: false,
      }) || i18n.t("validator-password")
    );
  }

  static samePasswordValidation(inputToValidate, otherPassword) {
    return (
      inputToValidate === otherPassword || i18n.t("validator-password-confirm")
    );
  }

  static internationalMobileNumber(inputToValidate) {
    if (inputToValidate === undefined || inputToValidate.length === 0)
      return true;
    return (
      isMobilePhone(inputToValidate, "any", { strictMode: true }) ||
      i18n.t("validator-international-phone")
    );
  }

  static webSiteUrlValidator(inputToValidate) {
    if (inputToValidate === undefined || inputToValidate.length === 0)
      return true;
    return isURL(inputToValidate, {}) || i18n.t("validator-url");
  }

  static emailValidation(inputToValidate) {
    if (inputToValidate === undefined || inputToValidate.length === 0)
      return true;
    return (
      isEmail(inputToValidate, { allow_underscores: true }) ||
      i18n.t("validator-email")
    );
  }

  static integerPositiveNumber(inputToValidate) {
    if (inputToValidate === undefined || inputToValidate.length === 0)
      return true;

    if (typeof inputToValidate === "number") {
      inputToValidate = inputToValidate.toString();
    }
    return (
      isInt(inputToValidate, { min: 0 }) || i18n.t("validator-integer-positive")
    );
  }
}

/* 
ISTRUZIONI PER L'UTILIZZO DELLE RULES IN OGNI VISTA

1) Importare i validators
import { Validators } from "../../utility/validators.js";

2) Inserire le rules nei data del componente
rules: { ...Validators.rules },

3) Inserire nell'html un v-form che racchiuda tutti i campi con delle rules ed assegnargli un ref (in questo caso form)
<v-form ref="form">
.....
</v-form>

4) Assegnare le specifiche rules come attributes di un determinato campo di input all'interno di un array
<v-text-field :rules="[ rules.required, rules.integerNumber ]"

5) Creare un metodo che validi il form 
validateFields: function() {
    return this.$refs.form.validate();
},

6) Porre il metodo di validazione precedentemente creato come condizione per il salvataggio dei dati
saveData: async function() {
    if(this.validateFields) {
        do something ...
    }
}

7) Eventualmente creare una computed ed un watcher per cambiare la virgola dei numeri decimali con un punto
computed: {
    inputDecimalList: function() {
        let dataModelsList = [
            "objectModel1.key1",
            "objectModel1.key2",
            "model2"
        ]
        let object = {
            values: `${this.objectModel1.key1}|${this.objectModel1.key2}|${this.model2}`,
            models: [...dataModelsList]
        }
        return object
    },
},
watch: {
    inputDecimalList: function(value) {
        const vm = this;
        Formatters.formatAllDecimalNumberWithDot(value, vm)
    }
},
*/

/* 
ISTRUZIONI PER L'AGGIUNTA DI UNA RULE ALL'INTERNO DELL'OGGETTO RULES

1) Creare una funzione che esegue la validazione
static requiredValidation(inputToValidate) {
    return (inputToValidate !== null && inputToValidate !== undefined && inputToValidate !== "") || "Required";
}

2) Assegnarla come ad una chiave dell'oggetto rules tramite arrow function
static rules = {
    required: (value) => this.requiredValidation(value),
    ...
}
*/

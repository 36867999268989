import Cookie from "@/utilities/cookie";

export default class Utils {
  static isBlank(str) {
    return !str || str.trim().length == 0;
  }

  static isObject(obj) {
    return Object.prototype.toString.call(obj) === "[object Object]";
  }

  static isString(x) {
    return Object.prototype.toString.call(x) === "[object String]";
  }

  static isNull(obj) {
    if (obj === undefined || obj === null) return true;
    if (Utils.isString(obj) && obj.trim().length == 0) return true;
    return false;
  }

  static getQueryParameter(name) {
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.href);
    if (results == null) return "";
    else return results[1];
  }

  static addQueryParameter(key, value) {
    var currUrl = new URL(window.location.href);
    currUrl.searchParams.set(key, value);
    window.history.replaceState("", "", currUrl);
  }

  static removeQuery() {
    let url = window.location.href;
    let urlWithoutQuery = url.split("?")[0];
    window.history.replaceState("", "", urlWithoutQuery);
  }

  static reload() {
    window.location.reload(true);
  }

  static logout() {
    let sid = Utils.getQueryParameter("sid");
    let json = Cookie.read("tokenList");
    let tokenList = JSON.parse(json);
    tokenList = tokenList.filter((x) => x.sid != sid);
    json = JSON.stringify(tokenList);
    Cookie.create("tokenList", json);
    Utils.reload();
  }

  static compareYMDDates(dateString1, dateString2) {
    let dateInt1 =
      parseInt(dateString1.substring(0, 4)) * 10000 +
      parseInt(dateString1.substring(5, 7)) * 100 +
      parseInt(dateString1.substring(8));
    let dateInt2 =
      parseInt(dateString2.substring(0, 4)) * 10000 +
      parseInt(dateString2.substring(5, 7)) * 100 +
      parseInt(dateString2.substring(8));
    return dateInt1 - dateInt2;
  }

  static intToDate(int) {
    let s = int.toString();

    return (
      s.substring(0, 4) + "-" + s.substring(4, 6) + "-" + s.substring(6, 8)
    );
  }

  static dateToInt(date) {
    return (
      parseInt(date.substring(0, 4)) * 10000 +
      parseInt(date.substring(5, 7)) * 100 +
      parseInt(date.substring(8, 10))
    );
  }

  static parsePercentile(str) {
    let value = undefined;
    if (typeof str != "string") return undefined;
    if (str.length > 0 && str.charAt(str.length - 1) == ".") return undefined;
    if (str.length > 0 && str.charAt(str.length - 1) == "%") {
      str = str.substring(0, str.length - 1);
      if (isNaN(str) || isNaN(parseFloat(str))) return undefined;
      value = parseFloat(str);
      if (value <= 0 || value >= 100) return undefined;
    } else {
      if (isNaN(str) || isNaN(parseFloat(str))) return undefined;
      value = parseFloat(str) * 100;
      if (value <= 0 || value >= 100) return undefined;
    }
    return value;
  }

  static range(start, count) {
    let a = [];
    for (var i = start; i < start + count; i++) a.push(i);
    return a;
  }

  static getCurrentYear() {
    let today = new Date();
    return today.getFullYear();
  }

  static trim(date) {
    return date.split("T")[0];
  }

  static getYear(date) {
    return date.split("-")[0];
  }

  static semesterFormatter(date) {
    var startDate = Utils.trim(date);
    var year = startDate.split("-")[0];
    var month = startDate.split("-")[1];
    var t;
    if (month < 6) {
      t = 1;
    } else if (month >= 6) {
      t = 2;
    }
    return year + " - T" + t;
  }

  static downloadFile(url, name) {
    const link = document.createElement("a");
    link.href = url;
    link.download = name;
    link.click();
    link.remove();
  }

  static toHex(bytes) {
    return [...new Uint8Array(bytes)]
      .map((x) => x.toString(16).padStart(2, "0"))
      .join("");
  }

  static base64ToBlob(base64, type) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: type });
  }

  static randomHex(length) {
    let zeros = "";
    for (var i = 0; i < length; i++) zeros = `0${zeros}`;
    let random_array = Uint8Array.from(zeros);
    window.crypto.getRandomValues(random_array);
    return Utils.array2hex(random_array);
  }

  static array2hex(list) {
    let res = [];
    for (var b of list) res.push(Utils.byte2hex(b));
    return res.join("");
  }

  static byte2hex(b) {
    const digits = "0123456789abcdef";
    let l = b % 16;
    let h = (b - l) / 16;
    return `${digits[h]}${digits[l]}`;
  }

  static saveChallenge(code_verifier) {
    let sid = Utils.gup("sid");
    if (sid == "") return;
    var challengeListJson = Utils.readCookie("challengeList");
    if (challengeListJson) {
      let challengeList = JSON.parse(challengeListJson);
      while (challengeList.length >= 5) challengeList.shift();
      challengeList.push({ sid: sid, code_verifier: code_verifier });
      Utils.createCookie("challengeList", JSON.stringify(challengeList), 0);
    } else {
      let challengeList = [{ sid: sid, code_verifier: code_verifier }];
      Utils.createCookie("challengeList", JSON.stringify(challengeList), 0);
    }
  }

  static gup(name) {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.href);
    if (results == null) return "";
    else return results[1];
  }

  static readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  static createCookie(name, value, days) {
    let expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  }

  static makeQuery(obj) {
    let q = `?`;
    for (var key in obj) {
      if (obj[key] != undefined) q = `${q}&${key}=${obj[key]}`;
    }
    if (q.length == 1) return ``;
    return q;
  }

  static first(list) {
    if (!list || list.length == 0) return undefined;
    return list[0];
  }

  static convertPermissionsToArray(permissions) {
    var result = [];
    for (var i = 0; i < 32; i++) {
      if ((permissions & (1 << i)) !== 0) {
        result.push(i + 1);
      }
    }
    return result;
    //? SE LE PERMISSION SARANNO MAI PIU' DI 32, UTILIZZARE LA LIBRERIA BigInt perchè JavaScript converte automaticamente i numeri a 64 bit in numeri a 32 bit quando si eseguono operazioni bitwise su di essi
    /* var result = [];
      var bigIntPermissions = BigInt(permissions);
      for (var i = 0; i < 64; i++) {
        if ((bigIntPermissions & (BigInt(1) << BigInt(i))) !== 0) {
          result.push(i + 1);
        }
      }
      return result;
    */
  }

  static complexObjClone(obj) {
    // Se obj è una Map
    if (obj instanceof Map) {
      const copyMap = new Map();
      obj.forEach((value, key) => {
        copyMap.set(key, this.complexObjClone(value));
      });
      return copyMap;
    }

    // Se obj è un Array
    if (Array.isArray(obj)) {
      return obj.map((item) => this.complexObjClone(item));
    }

    // Se obj è un oggetto
    if (typeof obj === "object" && obj !== null) {
      const copyObj = {};
      for (let key in obj) {
        if (Object.hasOwn(obj, key)) {
          copyObj[key] = this.complexObjClone(obj[key]);
        }
      }
      return copyObj;
    }
    return obj;
  }
}

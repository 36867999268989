import Ast from "./ast.js";
import Parser from "./parser.js";
import Diff from "./differences.js";

export default class Grid {
  static setValue(workbook, table, ri, ck, value) {
    try {
      let row = table.sheet[ri]
      let cell = row[ck]
      let ref = {
        ri: ri,
        ck: ck
      }
      if (!cell.e) return ref
      if (cell.s) {
        let ttb = workbook.tableMap.get(cell.s.r.table)
        let tri = cell.s.r.r     // setter->reference->row
        let tci = cell.s.r.c     // setter->reference->column
        let tck = ttb.cols.keys[tci]
        let val = Ast.evaluate(workbook, workbook.data, cell.s.f, value) // setter->formula
        Grid.setValue(workbook, ttb, tri, tck, val)
        ref.ri = tri;
        ref.ck = tck;
      }
      else {
        cell.x = value
      }
      return ref
    }
    catch (err) {
      console.log("Error in function : setValue, impossible to update the value of the cell");
      console.log(err);
    }
  }

  static invalidate(workbook) {
    Grid.forEachCell(workbook, (table, row, cell, ri, ci) => {
      if (cell && cell.f != undefined) {
        cell.u = false
      }
    })
  }

  static invalidateRelatedCells(workbook, table, ri, ck) {
    try {
      const cellsToUpdate = new Set();
      this.invalidateRelatedCellsRecoursive(workbook, cellsToUpdate, table, ri, ck);
      return cellsToUpdate;
    }
    catch (err) {
      console.log("Error in function : invalidateRelatedCells, impossible to invalidate the related cells");
      console.log(err);
      return undefined;
    }
  }

  static invalidateRelatedCellsRecoursive(workbook, cellsToUpdate, table, ri, ck) {
    // find my cell
    let row = table.sheet[ri];
    let cell = row[ck];

    // pass only if the cell has not been already setted to "to update"
    let init = cell.x == undefined
    if (cell.u == false || init) return

    cell.u = false;
    let obj = this.composeObj(table.name, ri, ck, cell);
    this.addToCellsToUpdate(cellsToUpdate, obj);

    
    // invalidate all the parents 
    /* for (let p of cell.p) {
      let other_table = workbook.tableMap.get(p.table);
      let r = p.r;
      let c = other_table.cols.keys[p.c];
      this.invalidateRelatedCellsRecoursive(workbook, cellsToUpdate, other_table, r, c);
    } */

    // invalidate all the dependecies
    for (let d of cell.d) {
      let other_table = workbook.tableMap.get(d.table);
      let r = d.r;
      let c = other_table.cols.keys[d.c];
      this.invalidateRelatedCellsRecoursive(workbook, cellsToUpdate, other_table, r, c);
    }
  }

  static update(workbook, cell, data) {
    let init = cell.x == undefined
    if (!init && cell.u) return
    for (let p of cell.p) // precedents
    {
      let other_table = workbook.tableMap.get(p.table)
      let other_row = other_table.sheet[p.r]
      let other_cell = other_row[other_table.cols.keys[p.c]]
      if (other_cell.u == false) Grid.update(workbook, other_cell, data)
    }
    let readonly = !cell.e
    let hasSetter = cell.s != undefined
    let calc = init || readonly || hasSetter
    if (calc && cell.f != undefined) cell.x = Ast.evaluate(workbook, data, cell.f)
    else if (calc && cell.v != undefined) cell.x = cell.v
    cell.u = true
  }

  static updateAll(workbook, data) {
    Grid.invalidate(workbook)
    
    Grid.forEachCell(workbook, (table, row, cell, ri, ci) => {  
      if (cell) {
        try {
          Grid.update(workbook, cell, data)
        }
        catch (err) {
          console.log("Error in table" + table.label + " row = " + ri + " col = " + ci);
          console.log("Error in function : update, impossible to update the related cells");
          console.log(err);
        }
      }
    })
    Grid.updateRowData(workbook)
  }

  static updateRelatedCells(workbook, table, data, ri, ck) {
    // invalidate
    let setOfCellsToUpdate = Grid.invalidateRelatedCells(workbook, table, ri, ck);
    const myIteratorOfCells = setOfCellsToUpdate.values();
    for (const elements of myIteratorOfCells) {
      let cell = elements.cell;
      if (cell) {
        try {
          Grid.update(workbook, cell, data);
        }
        catch (err) {
          console.log("Error in function : update, impossible to update the related cells");
          console.log(err);
        }
      }
    }
  }

  static updateRowData(workbook) {
    try {
      for (let table of workbook.tables) {
        table.items = Grid.getRowData(workbook, table.name) //TODO: QUA IL PROBLEMA DEL SAVE BUSINESS PLAN
      }
    } catch (err) {
      console.log("Error in function : updateRowData -> getRowData, impossible to get and update Row Data");
      console.log(err);
    }
  }

  static getRowData(workbook, tableName) {
    let table = workbook.tableMap.get(tableName)
    let rowData = table.sheet.map(r => { return {} })
    Grid.forEachTableCell(table, (table, row, cell, ri, ci) => {
      let ck = table.cols.keys[ci]
      if (cell) {
        rowData[ri][ck] = {
          v: cell.x,
          e: !!cell.e,
          b: !!cell.b,
          t: cell.t,
          ri: ri,
          ci: ci,
          ck: ck,
          y: cell.y,
          z: cell.z,
          k: cell.k,
          i: !!cell.i
        }
      }
      else {
        rowData[ri][ck] = {
          v: "",
          e: false,
          t: 'txt',
          ri: ri,
          ci: ci,
          ck: ck
        }
      }
    })
    if (table.spacing) {
      for (let ri of table.spacing) rowData[ri].spacing = true
    }
    return rowData
  }

  static forEachCell(workbook, lambda) {
    for (let table of workbook.tables) {
      for (let ri = 0; ri < table.rows.keys.length; ri++) {
        let row = table.sheet[ri]
        for (let ci = 0; ci < table.cols.keys.length; ci++) {
          let cell = row[table.cols.keys[ci]]
          lambda(table, row, cell, ri, ci)
        }
      }
    }
  }

  static constraintsForEachCellInRow(constraints, oldWorkbook, newWorkbook) {
    constraints.forEach(constraint => {
      let newTable = newWorkbook.tables.find(table => table.name == constraint.table)
      let ri = newTable.rows.key2idx.get(constraint.r)
      let newRowItem = newTable.items[ri]
      for (let ci = 0; ci < newTable.cols.keys.length; ci++) {
        let newCellItem = newRowItem[newTable.cols.keys[ci]];
        let prevValue = this.evaluateFormula(constraint, oldWorkbook, ci, ri)
        let actualValue = newCellItem.v
        let value;
        if (constraint.type == "ge") {
          value = actualValue >= prevValue ? actualValue : prevValue
        }
        this.setValue(newWorkbook, newTable, ri, newCellItem.ck, value)
        this.updateRelatedCells(newWorkbook, newTable, newWorkbook.data, ri, newCellItem.ck);
      } 
    });
  }

  static anchorsForEachCellInRow(anchors, oldWorkbook, newWorkbook) {
    anchors.forEach(anchor => {
      let oldTable = oldWorkbook.tables.find(table => table.name == anchor.table)
      let newTable = newWorkbook.tables.find(table => table.name == anchor.table)
      let ri = oldTable.rows.key2idx.get(anchor.r)
      let oldRowItem = oldTable.items[ri]
      let newRowItem = newTable.items[ri]
      let oldValues = {};
      for (let ci = 0; ci < oldTable.cols.keys.length; ci++) {
        let oldCellItem = oldRowItem[oldTable.cols.keys[ci]];
        let oldValue = oldCellItem.v;
        oldValues[oldCellItem.ck] = oldValue;
      }
      for (let ci = 0; ci < newTable.cols.keys.length; ci++) {
        let newCellItem = newRowItem[newTable.cols.keys[ci]];
        this.setValue(newWorkbook, newTable, ri, newCellItem.ck, oldValues[newCellItem.ck])
        this.updateRelatedCells(newWorkbook, newTable, newWorkbook.data, ri, newCellItem.ck);
      } 
    });
  }

  static forEachTableCell(table, lambda) {
    for (let ri = 0; ri < table.rows.keys.length; ri++) {
      let row = table.sheet[ri]
      for (let ci = 0; ci < table.cols.keys.length; ci++) {
        let cell = row[table.cols.keys[ci]]
        lambda(table, row, cell, ri, ci)
      }
    }
  }

  static getColumnData(workbook, tableObject, columnKey) {
    try {
      let table = workbook.tableMap.get(tableObject.name);
      let finalColumnArray = [];
      for (let ri = 0; ri < table.rows.keys.length; ri++) {
        let voiceObjectValue = {
          "codice_sezione": undefined,
          "codice_voce": undefined,
          "voce": undefined,
          "valore": undefined
        };
        let rowKey = table.rows.keys[ri]
        let stringKeyArray = rowKey.split("_")
        voiceObjectValue["codice_sezione"] = stringKeyArray[1];
        voiceObjectValue["codice_voce"] = stringKeyArray[2];

        let row = table.sheet[ri]
        let cell = row[columnKey]
        voiceObjectValue["valore"] = cell.x;

        voiceObjectValue["voce"] = tableObject.items[ri].key.v;

        finalColumnArray.push(voiceObjectValue);
      }
      return finalColumnArray
    }
    catch (err) {
      console.log("impossible to get the column in table = " + table.label + " col key = " + columnKey);
      return undefined;
    }
  }

  static addToCellsToUpdate(set, obj) {
    const myIteratorOfCells = set.values();

    let canI_Enter = true
    for (const entry of myIteratorOfCells)
      if (entry.name == obj.name && entry.row == obj.row && entry.col == obj.col)
        canI_Enter = false

    if (canI_Enter) set.add(obj);
  }

  static composeObj(n_, r_, c_, cell_) {
    return { name: n_, row: r_, col: c_, cell: cell_ }
  }

  static check(obj1, obj2) {
    if (JSON.stringify(obj1) == JSON.stringify(obj2))
      return true;
    else
      return Diff.findDifferences(obj1, obj2);
  }

  static evaluateFormula(constraint, oldWorkbook, ci, ri){
    let ast = Parser.parse(constraint.f)
    let table = oldWorkbook.tables.find(table => table.name == constraint.table)
    Ast.translateFormula(oldWorkbook, ast, table, ri, ci)
    return Ast.evaluate(oldWorkbook, oldWorkbook.data, ast)
  }
}
import { axiosClient } from "../axios";
import { getQuery } from "../helper";

const baseUrl = "/api/docs/balances";

export function getSchema(params) {
  const query = getQuery(params);
  return axiosClient.get(`${baseUrl}/schema`, query);
}

export function getConstraints() {
  return axiosClient.get(`${baseUrl}/constraints`);
}

export function getAddons() {
  return axiosClient.get(`${baseUrl}/addons`);
}

export function download(options) {
  const query = getQuery(options);
  return axiosClient.get(`${baseUrl}/download`, query);
}

export function get(id) {
  return axiosClient.get(`${baseUrl}/${id}`);
}

export function getByEnterpriseId(enterpriseId) {
  const query = getQuery({ enterpriseId });
  return axiosClient.get(baseUrl, query);
}

export function getBlobByHash(hash) {
  return axiosClient.get(`${baseUrl}/blob/${hash}`);
}

export function save(data) {
  return axiosClient.post(`${baseUrl}/save`, data);
}

export function remove(id) {
  const query = getQuery({ id });
  return axiosClient.post(`${baseUrl}/delete`, {}, query);
}

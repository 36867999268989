<template>
  <v-overlay :value="isLoading" absolute z-index="200">
    <v-progress-circular indeterminate size="100" color="white" />
  </v-overlay>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TheLoader",
  computed: {
    ...mapGetters({
      isLoading: "shared/getLoading",
    }),
  },
};
</script>

import { RegistrationApi } from "@/api";
import i18n from "@/i18n";
import { showSuccessSnackbar } from "@/utilities/snackbar";

export async function registerEnterprise(data) {
  await RegistrationApi.registerEnterprise(data);
  showSuccessSnackbar(200, i18n.t("enterprise-created-correctly"));
}

export async function registerEnterpriseUser(data) {
  await RegistrationApi.registerEnterpriseUser(data);
}

export async function registerFirmUser(data) {
  await RegistrationApi.registerFirmUser(data);
  showSuccessSnackbar(
    200,
    `${i18n.t("email-sent-pt-1")} "${data.email}" ${i18n.t("email-sent-pt-2")}`
  );
}

export async function validateEnterpriseEmail(token, newPassword) {
  await RegistrationApi.validateEnterpriseEmail(token, newPassword);
  showSuccessSnackbar(200, i18n.t("registration-success"));
}

export async function validateAccountantEmail(token, newPassword) {
  await RegistrationApi.validateAccountantEmail(token, newPassword);
  showSuccessSnackbar(200, i18n.t("registration-success"));
}

import { EnterpriseNotApplicableEsgEligibilityTestApi } from "@/api";

export async function get(enterpriseId) {
  const { data } = await EnterpriseNotApplicableEsgEligibilityTestApi.get(
    enterpriseId
  );
  return data;
}

export async function save(
  esgEligibilityTestId,
  enterpriseId,
  isNotApplicable
) {
  const { data } = await EnterpriseNotApplicableEsgEligibilityTestApi.save(
    esgEligibilityTestId,
    enterpriseId,
    isNotApplicable
  );
  return data;
}

<template>
  <v-footer dark app padless style="z-index: 90">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card color="techBlueDark">
          <v-card-text class="text-center pa-1">
            {{ text }}
            <a
              href="https://www.technesthai.com/"
              class="text-decoration-none white--text"
              target="_blank"
            >
              Technesthai
            </a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "TheFooter",
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    text() {
      return "© " + this.currentYear + " - " + this.$t("developed-by");
    },
  },
};
</script>

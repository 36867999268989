import { CompanySuggesterApi } from "@/api";

export async function getNames(name) {
  const { data } = await CompanySuggesterApi.getNames(name);
  return data;
}

export async function getData(name, nation) {
  const { data } = await CompanySuggesterApi.getData(name, nation);
  return data;
}

export async function getAddress(id) {
  const { data } = await CompanySuggesterApi.getAddress(id);
  return data;
}

<template>
  <v-app-bar app elevation="1" color="white" :style="{ 'z-index': 199 }">
    <TheBreadcrumb :label="viewName" />
    <v-spacer />
    <VisualizationBtnDropdown v-if="showVisualization" />
    <ScenarioBtnDropdown v-if="dropdown.scenario" />
    <template v-if="showAddons">
      <div class="mr-2">
        <SpreadsheetAddonDialog
          v-for="{ id, item } in addons"
          :key="id"
          :addon="item"
        />
      </div>
    </template>
    <v-btn
      v-for="button in buttons"
      :key="button.label"
      v-bind="button.props"
      depressed
      :to="button.to"
      class="mr-2"
      @click="button.callback"
    >
      <template v-if="button.icon && button.icon.props?.left">
        <v-icon v-bind="button.icon.props">
          {{ button.icon.label }}
        </v-icon>
      </template>
      <template v-if="button.label">{{ button.label }}</template>
      <template v-if="button.icon && button.icon.props?.right">
        <v-icon v-bind="button.icon.props">
          {{ button.icon.label }}
        </v-icon>
      </template>
      <template
        v-if="
          button.icon && !button.icon.props?.left && !button.icon.props?.right
        "
      >
        <v-icon v-bind="button.icon.props">{{ button.icon.label }}</v-icon>
      </template>
    </v-btn>
    <div v-if="showSearch" style="width: 230px">
      <v-text-field
        v-model="query"
        color="techBlue"
        append-icon="mdi-magnify"
        hide-details
        filled
        dense
        :placeholder="$t('start-search')"
      />
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TheBreadcrumb from "@/components/global/TheBreadcrumb.vue";
import VisualizationBtnDropdown from "../dropdown/VisualizationBtnDropdown.vue";
import ScenarioBtnDropdown from "../dropdown/ScenarioBtnDropdown.vue";
import SpreadsheetAddonDialog from "@/components/dialogs/SpreadsheetAddonDialog.vue";

export default {
  name: "TheHeader",
  components: {
    TheBreadcrumb,
    VisualizationBtnDropdown,
    ScenarioBtnDropdown,
    SpreadsheetAddonDialog,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      storeViewName: "header/getViewName",
      showSearch: "header/getShowSearch",
      searchQuery: "header/getSearchQuery",
      buttons: "header/getButtons",
      dropdown: "header/getDropdown",
      showAddons: "header/getShowAddons",
      addons: "workbook/getAddons",
    }),
    viewName() {
      return this.storeViewName ?? this.$t(this.$route.meta.title) ?? "Ops!";
    },
    showVisualization() {
      return !!this.$route.meta.header?.buttons?.visualization ?? false;
    },
    query: {
      get() {
        return this.searchQuery;
      },
      set(value) {
        this.setSearchQuery(value);
      },
    },
  },
  methods: {
    ...mapActions({
      setSearchQuery: "header/setSearchQuery",
    }),
  },
};
</script>

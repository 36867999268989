import { axiosClient } from "../axios";

const baseUrl = "/api/registry/firms";

export function getAll() {
  return axiosClient.get(baseUrl);
}

export function get(id) {
  return axiosClient.get(`${baseUrl}/${id}`);
}

export function registration(data) {
  return axiosClient.post(baseUrl, data);
}

export function update(firmId, data) {
  return axiosClient.put(`${baseUrl}/${firmId}`, data);
}

import { BusinessPlanApi } from "@/api";
import i18n from "@/i18n";
import router from "@/router";
import { showSuccessSnackbar } from "@/utilities/snackbar";
import { JsonUtils } from "@/utilities/jsonUtils";

export async function getSchema() {
  const { data } = await BusinessPlanApi.getSchema();
  return data;
}

export async function getConstraints() {
  const { data } = await BusinessPlanApi.getConstraints();
  return data;
}

export async function getAddons() {
  const { data } = await BusinessPlanApi.getAddons();
  return data;
}

export async function getEasyModeTemplate() {
  const { data } = await BusinessPlanApi.getEasyModeTemplate();
  return data;
}

export async function get(id) {
  const { data } = await BusinessPlanApi.get(id);
  return data;
}

export async function getByEnterpriseId(id) {
  const { data } = await BusinessPlanApi.getByEnterpriseId(id);
  return data;
}

export async function getBlobByHash(hash) {
  let { data } = await BusinessPlanApi.getBlobByHash(hash);
  data = JSON.parse(data, JsonUtils.infinityReviver);
  return data;
}

export async function getOriginalWorkbook(scenarioId) {
  let data;
  try {
    ({ data: data } = await BusinessPlanApi.getOriginalWorkbook(scenarioId));
  } catch (ex) {
    if (ex.response.status === 404) {
      // TODO: check if it's the correct move
      router.push({ name: "business-plans" });
    }
  }
  return data;
}

export async function save(businessPlan) {
  const { data } = await BusinessPlanApi.save(businessPlan);
  showSuccessSnackbar(200, i18n.t("business-plan-saved-correctly"));
  return data;
}

export async function rename(id, name) {
  await BusinessPlanApi.rename(id, name);
  showSuccessSnackbar(200, i18n.t("business-plan-renamed-correctly"));
}

export async function remove(id) {
  await BusinessPlanApi.remove(id);
  showSuccessSnackbar(200, i18n.t("business-plan-deleted-correctly"));
}

export async function exportToExcel(businessPlan) {
  const { data } = await BusinessPlanApi.exportToExcel(businessPlan);
  return data;
}

export async function getJsonData() {
  const { data } = await BusinessPlanApi.getJsonData();
  return data;
}

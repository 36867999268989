<template>
  <v-dialog v-model="dialog" max-width="600">
    <template #activator="{ on, attrs }">
      <v-list-item :disabled="disabled" v-bind="attrs" v-on="on">
        <v-list-item-icon>
          <v-icon>mdi-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("add-scenario") }}</v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="techBlue white--text">
        {{ $t("scenario-creation") }}
      </v-card-title>
      <v-card-text class="pt-5">
        <v-form ref="form" class="d-flex justify-space-around align-center">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="scenarioName"
                color="techBlue"
                :label="$t('name')"
                outlined
                background-color="white"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="scenarioType"
                color="techBlue"
                :label="$t('scenario-type')"
                :items="scenarioList"
                outlined
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn plain @click="onClose">{{ $t("close") }}</v-btn>
        <v-btn color="success" @click="startCreatingScenario">{{
          $t("create")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Scenario } from "@/utilities/enums.js";
import { Validators } from "@/utilities/validators.js";
import { mapActions, mapGetters } from "vuex";
import { ScenarioService } from "@/services";

export default {
  name: "ScenarioCreationDialog",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    businessPlanId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    scenarioName: undefined,
    scenarioType: undefined,

    rules: { ...Validators.rules },
    dialog: false,
  }),
  computed: {
    ...mapGetters({
      userEnterpriseId: "user/getUserEnterpriseId",
    }),
    scenarioList() {
      return Scenario.toSelectOptions();
    },
  },
  methods: {
    ...mapActions({
      setSharedData: "shared/setSharedData",
    }),
    validateFields() {
      return this.$refs.form.validate();
    },
    async startCreatingScenario() {
      if (!this.validateFields()) return;

      const scenariosSchema = await ScenarioService.getSchema();
      const selectedScenario = scenariosSchema.find(
        (scenario) => scenario.id == this.scenarioType
      );

      if (!selectedScenario) {
        console.error("Scenario not found");
        return;
      }

      this.dialog = false;

      const scenarioProps = {
        name: this.scenarioName,
        type: this.scenarioType,
      };

      const payload = {
        scenarioParams: selectedScenario,
        scenarioProps,
      };

      this.setSharedData(payload);

      this.$router.push({ name: "create-scenario" });
    },
    onClose() {
      this.$refs.form.reset();
      this.dialog = false;
    },
  },
};
</script>

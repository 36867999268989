const getDefaultState = () => {
  return {
    scenario: undefined,
    anchorsDialog: undefined,
    readOnly: true,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getScenario: (state) => {
      return state.scenario;
    },
    getOptions: (state) => {
      return state.scenario?.options || {};
    },
    getAnchorsDialog: (state) => {
      return state.anchorsDialog;
    },
    isReady: (state) => {
      return state.scenario !== undefined;
    },
    isReadOnly: (state) => {
      return state.readOnly;
    },
  },
  mutations: {
    SET_SCENARIO(state, scenario) {
      state.scenario = scenario;
    },
    SET_ANCHORS_DIALOG(state, dialog) {
      state.anchorsDialog = dialog;
    },
    SET_READ_ONLY(state, readOnly) {
      state.readOnly = readOnly;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    setScenario({ commit }, payload) {
      commit("SET_SCENARIO", payload);
    },
    setAnchorsDialog({ commit }, payload) {
      commit("SET_ANCHORS_DIALOG", payload);
    },
    setReadOnly({ commit }, payload) {
      commit("SET_READ_ONLY", payload);
    },
    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};

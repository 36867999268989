<template>
  <v-dialog v-model="dialog" max-width="600">
    <template #activator="{ on, attrs }">
      <v-btn
        depressed
        :color="button.color || 'grey'"
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-icon v-if="button.icon" left>
          {{ button.icon }}
        </v-icon>
        {{ $t(button.label) }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="techBlue white--text">
        {{ $t(dialogData.title) }}
      </v-card-title>
      <v-card-text class="pa-5">
        <v-row>
          <v-col
            v-for="item in dialogData.fields"
            :key="item.key"
            :cols="item.cols || 6"
          >
            <component
              :is="item.component"
              v-model="models[item.key]"
              v-bind="{ props: item.props }"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn plain @click="dialog = false">{{ $t("close") }}</v-btn>
        <div v-for="(action, index) in dialogData.actions" :key="index">
          <v-btn
            :color="action.color || 'techBlue'"
            :dark="!disableActions"
            :loading="isLoading"
            :disabled="disableActions"
            @click="onClick(action)"
          >
            {{ $t(action.label) }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Ast from "@/components/grid/ast";
export default {
  name: "SpreadsheetAddonDialog",
  components: {
    TSelect: () => import("@/components/fields/wrapper/TSelect"),
    TTextField: () => import("@/components/fields/wrapper/TTextField"),
  },
  props: {
    addon: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    models: {},
    isLoading: false,
  }),
  computed: {
    disableActions() {
      return this.dialogData.fields.some((field) => {
        return !this.models[field.key];
      });
    },
    button() {
      return this.addon.button;
    },
    dialogData() {
      return this.addon.dialog;
    },
  },
  methods: {
    async onClick(button) {
      this.isLoading = true;
      await this.doTheWork(button);
    },
    async doTheWork(button) {
      setTimeout(() => {
        const args = [...button.event.args];
        for (let i = 0; i < args.length; i++) {
          args[i] = this.models[args[i]];
        }
        Ast[button.event.func](...args);
        this.dialog = false;
        this.isLoading = false;
      }, 200);
    },
  },
};
</script>

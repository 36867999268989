export default class diff {
  static findDifferences(objectA, objectB) {
    var propertyChanges = [];
    var objectGraphPath = ["this"];

    (function _self(a, b) {
      // <--- (1)
      if (a?.constructor == Array) {
        for (var i = 0; i < a.length; i++) {
          objectGraphPath.push("[" + i.toString() + "]");
          _self(a[i], b[i]); // <--- (2)

          objectGraphPath.pop();
        }
      } else if (
        a?.constructor == Object ||
        (a?.constructor != Number &&
          a?.constructor != String &&
          a?.constructor != Date &&
          a?.constructor != RegExp &&
          a?.constructor != Function &&
          a?.constructor != Boolean)
      ) {
        for (var property in a) {
          objectGraphPath.push("." + property);
          if (a[property]?.constructor != Function) {
            _self(a[property], b[property]); // <--- (3)
          }
          objectGraphPath.pop();
        }
      } else if (a?.constructor != Function) {
        if (a != b) {
          propertyChanges.push({
            Property: objectGraphPath.join(""),
            ObjectA: a,
            ObjectB: b,
          });
        }
      }
    })(objectA, objectB);

    return propertyChanges;
  }
}

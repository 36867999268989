import { axiosClient } from "../axios";
import { getQuery } from "../helper";

const baseUrl = "/company-data";

export function getNames(name) {
  const query = getQuery({ name });
  return axiosClient.get("company-names", query);
}

export function getData(name, nation) {
  const query = getQuery({ name, nation });
  return axiosClient.get(baseUrl, query);
}

export function getAddress(id) {
  return axiosClient.get(`${baseUrl}/${id}/address`);
}

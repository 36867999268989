export class JsonUtils {
  // Funzione infinityReplacer per JSON.stringify
  static infinityReplacer(key, value) {
    if (typeof value === "number" && !isFinite(value)) {
      return value === Infinity ? "Infinity" : "-Infinity";
    }
    return value;
  }

  // Funzione infinityReviver per JSON.parse
  static infinityReviver(key, value) {
    if (value === "Infinity") {
      return Infinity;
    }
    if (value === "-Infinity") {
      return -Infinity;
    }
    return value;
  }
}

import { UserApi } from "@/api";
import router from "@/router";

export async function getAllFromFirm(firmId = undefined) {
  const { data } = await UserApi.getAllFromFirm(firmId);
  return data;
}

export async function getByEnterpriseId(enterpriseId) {
  let data;
  try {
    ({ data: data } = await UserApi.getByEnterpriseId(enterpriseId));
  } catch (ex) {
    // TODO: Backend never returns 404, if it does, we shouldn't redirect to dashboard
    if (ex.response.status === 404) {
      router.push({ name: "dashboard" });
    }
  }
  return data;
}

export async function updateFirmUser(userId, data) {
  const { data: response } = await UserApi.updateFirmUser(userId, data);
  return response;
}

export async function toggleFirmUserEnabled(data) {
  data.deletedAt =
    data.deletedAt === undefined ? new Date().toISOString() : undefined;
  return this.updateFirmUser(data.id, data);
}

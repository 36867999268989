import i18n from "@/i18n";

function enumToSelectOptions(codes, labels, from, to) {
  let options = [];
  for (var i = 0; i < codes.length; i++) {
    let code = codes[i];
    let label = labels[i];
    if (from && code < from) continue;
    if (to && code >= to) continue;
    options.push({ value: code, text: label });
  }
  return options;
}

function enumCodeToLabel(codes, labels, code) {
  for (var i = 0; i < codes.length; i++) {
    if (codes[i] == code) return labels[i];
  }
  return "";
}

function enumLabelToCode(codes, labels, label) {
  for (var i = 0; i < labels.length; i++) {
    if (labels[i] == label) return codes[i];
  }
  return "";
}

export class GeneralEnumeration {
  static A = 0;
  static B = 1;
  static C = 2;
  static D = 3;

  static Codes = [this.A, this.B, this.C, this.D];
  static Labels = ["Alfa", "Beta", "Gamma", "Delta"];

  static toSelectOptions(from, to) {
    this.Labels = ["Alfa", "Beta", "Gamma", "Delta"];
    return enumToSelectOptions(this.Codes, this.Labels, from, to);
  }

  static codeToLabel(code) {
    this.Labels = ["Alfa", "Beta", "Gamma", "Delta"];
    return enumCodeToLabel(this.Codes, this.Labels, code);
  }
}

export class BalanceTypology {
  static REGISTERED = 1;
  static MANUAL = 2;

  static Codes = [this.REGISTERED, this.MANUAL];
  static Labels = [i18n.t("deposited"), i18n.t("manual")];

  static toSelectOptions(from, to) {
    this.Labels = [i18n.t("deposited"), i18n.t("manual")];
    return enumToSelectOptions(this.Codes, this.Labels, from, to);
  }

  static codeToLabel(code) {
    this.Labels = [i18n.t("deposited"), i18n.t("manual")];
    return enumCodeToLabel(this.Codes, this.Labels, code);
  }
}

export class BusinessPlanStatus {
  static PENDING = 1;
  static SUBMITTED = 2;

  static Codes = [this.PENDING, this.SUBMITTED];
  static Labels = [i18n.t("pending"), i18n.t("submitted")];

  static toSelectOptions(from, to) {
    this.Labels = [i18n.t("pending"), i18n.t("submitted")];
    return enumToSelectOptions(this.Codes, this.Labels, from, to);
  }

  static codeToLabel(code) {
    this.Labels = [i18n.t("pending"), i18n.t("submitted")];
    return enumCodeToLabel(this.Codes, this.Labels, code);
  }
}

export class DocumentTypes {
  static ENTERPRISE = 1;
  static PROJECT = 2;
  static OTHER = 3;
  static ESG = 4;
  static ESG_DOCUMENTATION = 5;

  static Codes = [
    this.ENTERPRISE,
    this.PROJECT,
    this.OTHER,
    this.ESG,
    this.ESG_DOCUMENTATION,
  ];
  static Labels = [
    i18n.t("enterprise"),
    i18n.t("project"),
    i18n.t("other"),
    i18n.t("esg.default"),
    i18n.t("esg.documentation"),
  ];

  static toSelectOptions(from, to) {
    this.Labels = [
      i18n.t("enterprise"),
      i18n.t("project"),
      i18n.t("other"),
      i18n.t("esg.default"),
      i18n.t("esg.documentation"),
    ];
    return enumToSelectOptions(this.Codes, this.Labels, from, to);
  }

  static codeToLabel(code) {
    this.Labels = [
      i18n.t("enterprise"),
      i18n.t("project"),
      i18n.t("other"),
      i18n.t("esg.default"),
      i18n.t("esg.documentation"),
    ];
    return enumCodeToLabel(this.Codes, this.Labels, code);
  }

  static labelToCode(label) {
    this.Labels = [
      i18n.t("enterprise"),
      i18n.t("project"),
      i18n.t("other"),
      i18n.t("esg.default"),
      i18n.t("esg.documentation"),
    ];
    return enumLabelToCode(this.Codes, this.Labels, label);
  }
}

export class BalanceDeposited {
  static NOT_DEPOSITED = 1;
  static DEPOSITED = 2;

  static Codes = [this.NOT_DEPOSITED, this.DEPOSITED];
  static Labels = [i18n.t("not-deposited"), i18n.t("deposited")];

  static toSelectOptions(from, to) {
    this.Labels = [i18n.t("not-deposited"), i18n.t("deposited")];
    return enumToSelectOptions(this.Codes, this.Labels, from, to);
  }

  static codeToLabel(code) {
    this.Labels = [i18n.t("not-deposited"), i18n.t("deposited")];
    return enumCodeToLabel(this.Codes, this.Labels, code);
  }
}

export class Scenario {
  static FATTURATO = 1;
  static COSTO_PRODUTTIVO = 2;
  static COSTO_CONSUMI = 3;
  static COSTO_DEL_LAVORO = 4;
  static TASSI_DI_INTERESSE = 5;
  static DISTRIBUZIONI_UTILI = 6;
  static AMMORTAMENTI = 7;
  static RIMODULAZIONE_NUOVI_INVESTIMENTI = 8;
  static AUMENTI_DI_CAPITALE = 9;
  static NUOVI_FINANZIAMENTI = 10;
  static RIMODULAZIONE_INVESTIMENTI_E_FINANZIAMENTI = 11;

  static Codes = [
    this.FATTURATO,
    this.COSTO_PRODUTTIVO,
    this.COSTO_CONSUMI,
    this.COSTO_DEL_LAVORO,
    this.TASSI_DI_INTERESSE,
    this.DISTRIBUZIONI_UTILI,
    this.AMMORTAMENTI,
    this.RIMODULAZIONE_NUOVI_INVESTIMENTI,
    this.AUMENTI_DI_CAPITALE,
    this.NUOVI_FINANZIAMENTI,
    this.RIMODULAZIONE_INVESTIMENTI_E_FINANZIAMENTI,
  ];
  static Labels = [
    i18n.t("Fatturato"),
    i18n.t("Costo Produttivo"),
    i18n.t("Costo Consumi"),
    i18n.t("Costo del Lavoro"),
    i18n.t("Tassi di Interesse"),
    i18n.t("Distribuzioni Utili"),
    i18n.t("Ammortamenti"),
    i18n.t("Rimodulazione Nuovi Investimenti"),
    i18n.t("Aumenti di Capitale"),
    i18n.t("Nuovi Finanziamenti"),
    i18n.t("Rimodulazione Investimenti e Finanziamenti"),
  ];

  static toSelectOptions(from, to) {
    this.Labels = [
      i18n.t("Fatturato"),
      i18n.t("Costo Produttivo"),
      i18n.t("Costo Consumi"),
      i18n.t("Costo del Lavoro"),
      i18n.t("Tassi di Interesse"),
      i18n.t("Distribuzioni Utili"),
      i18n.t("Ammortamenti"),
      i18n.t("Rimodulazione Nuovi Investimenti"),
      i18n.t("Aumenti di Capitale"),
      i18n.t("Nuovi Finanziamenti"),
      i18n.t("Rimodulazione Investimenti e Finanziamenti"),
    ];
    return enumToSelectOptions(this.Codes, this.Labels, from, to);
  }

  static codeToLabel(code) {
    this.Labels = [
      i18n.t("Fatturato"),
      i18n.t("Costo Produttivo"),
      i18n.t("Costo Consumi"),
      i18n.t("Costo del Lavoro"),
      i18n.t("Tassi di Interesse"),
      i18n.t("Distribuzioni Utili"),
      i18n.t("Ammortamenti"),
      i18n.t("Rimodulazione Nuovi Investimenti"),
      i18n.t("Aumenti di Capitale"),
      i18n.t("Nuovi Finanziamenti"),
      i18n.t("Rimodulazione Investimenti e Finanziamenti"),
    ];
    return enumCodeToLabel(this.Codes, this.Labels, code);
  }
}

export class Permissions {
  static REATE_FIRM = 1;
  static EDIT_FIRM = 2;
  static VIEW_FIRMS = 3;
  static CREATE_FIRM_USER = 4;
  static EDIT_FIRM_USER = 5;
  static VIEW_FIRM_USERS = 6;
  static MANAGE_FIRM_USER = 7;
  static CREATE_ENTERPRISE = 8;
  static EDIT_ENTERPRISE = 9;
  static VIEW_ENTERPRISES = 10;
  static CREATE_ENTERPRISE_USER = 11;
  static EDIT_ENTERPRISE_USER = 12;
  static VIEW_ENTERPRISE_USERS = 13;
  static MANAGE_ENTERPRISE_USER = 14;
  static CREATE_ROLE = 15;
  static EDIT_ROLE = 16;
  static VIEW_ROLE = 17;
  static UPLOAD_DOCUMENT = 18;
  static VIEW_DOCUMENT = 19;
  static SUBMIT_DOCUMENT = 20;
  static MANAGE_DOCUMENT = 21;
  static DOWNLOAD_BALANCE = 22;
  static VIEW_BALANCE = 23;
  static EDIT_BALANCE = 24;
  static CREATE_BUSINESS_PLAN = 25;
  static EDIT_BUSINESS_PLAN = 26;
  static VIEW_BUSINESS_PLAN = 27;
  static SUBMIT_BUSINESS_PLAN = 28;
  static MANAGE_BUSINESS_PLAN = 29;

  static Codes = [
    this.CREATE_FIRM,
    this.EDIT_FIRM,
    this.VIEW_FIRMS,
    this.CREATE_FIRM_USER,
    this.EDIT_FIRM_USER,
    this.VIEW_FIRM_USERS,
    this.MANAGE_FIRM_USER,
    this.CREATE_ENTERPRISE,
    this.EDIT_ENTERPRISE,
    this.VIEW_ENTERPRISES,
    this.CREATE_ENTERPRISE_USER,
    this.EDIT_ENTERPRISE_USER,
    this.VIEW_ENTERPRISE_USERS,
    this.MANAGE_ENTERPRISE_USER,
    this.CREATE_ROLE,
    this.EDIT_ROLE,
    this.VIEW_ROLE,
    this.UPLOAD_DOCUMENT,
    this.VIEW_DOCUMENT,
    this.SUBMIT_DOCUMENT,
    this.MANAGE_DOCUMENT,
    this.DOWNLOAD_BALANCE,
    this.VIEW_BALANCE,
    this.EDIT_BALANCE,
    this.CREATE_BUSINESS_PLAN,
    this.EDIT_BUSINESS_PLAN,
    this.VIEW_BUSINESS_PLAN,
    this.SUBMIT_BUSINESS_PLAN,
    this.MANAGE_BUSINESS_PLAN,
  ];
  static Labels = [
    "CREATE_FIRM",
    "EDIT_FIRM",
    "VIEW_FIRMS",
    "CREATE_FIRM_USER",
    "EDIT_FIRM_USER",
    "VIEW_FIRM_USERS",
    "MANAGE_FIRM_USER",
    "CREATE_ENTERPRISE",
    "EDIT_ENTERPRISE",
    "VIEW_ENTERPRISES",
    "CREATE_ENTERPRISE_USER",
    "EDIT_ENTERPRISE_USER",
    "VIEW_ENTERPRISE_USERS",
    "MANAGE_ENTERPRISE_USER",
    "CREATE_ROLE",
    "EDIT_ROLE",
    "VIEW_ROLE",
    "UPLOAD_DOCUMENT",
    "VIEW_DOCUMENT",
    "SUBMIT_DOCUMENT",
    "MANAGE_DOCUMENT",
    "DOWNLOAD_BALANCE",
    "VIEW_BALANCE",
    "EDIT_BALANCE",
    "CREATE_BUSINESS_PLAN",
    "EDIT_BUSINESS_PLAN",
    "VIEW_BUSINESS_PLAN",
    "SUBMIT_BUSINESS_PLAN",
    "MANAGE_BUSINESS_PLAN",
  ];

  static toSelectOptions(from, to) {
    return enumToSelectOptions(this.Codes, this.Labels, from, to);
  }

  static codeToLabel(code) {
    return enumCodeToLabel(this.Codes, this.Labels, code);
  }
}

export class EsgGraphNodeTypes {
  static QUESTION = 1;
  static ANSWER = 2;
  static STATUS = 3;
  static RECALL = 4;

  static Codes = [this.QUESTION, this.ANSWER, this.STATUS, this.RECALL];

  static Labels = ["QUESTION", "ANSWER", "STATUS", "RECALL"];

  static toSelectOptions(from, to) {
    return enumToSelectOptions(this.Codes, this.Labels, from, to);
  }

  static codeToLabel(code) {
    return enumCodeToLabel(this.Codes, this.Labels, code);
  }
}

export class EsgObjectiveTypes {
  static MITIGATION = 1;
  static ADAPTATION = 2;
  static WATER = 3;
  static CIRCULAR_ECONOMY = 4;
  static POLLUTION_PREVENTION = 5;
  static BIODIVERSITY = 6;

  static Codes = [
    this.MITIGATION,
    this.ADAPTATION,
    this.WATER,
    this.CIRCULAR_ECONOMY,
    this.POLLUTION_PREVENTION,
    this.BIODIVERSITY,
  ];

  static Labels = [
    i18n.t("esg.objectives.MITIGATION"),
    i18n.t("esg.objectives.ADAPTATION"),
    i18n.t("esg.objectives.WATER"),
    i18n.t("esg.objectives.CIRCULAR_ECONOMY"),
    i18n.t("esg.objectives.POLLUTION_PREVENTION"),
    i18n.t("esg.objectives.BIODIVERSITY"),
  ];

  static Description = [
    i18n.t("esg.objectives.MITIGATION-description"),
    i18n.t("esg.objectives.ADAPTATION-description"),
    i18n.t("esg.objectives.WATER-description"),
    i18n.t("esg.objectives.CIRCULAR_ECONOMY-description"),
    i18n.t("esg.objectives.POLLUTION_PREVENTION-description"),
    i18n.t("esg.objectives.BIODIVERSITY-description"),
  ];

  static toSelectOptions(from, to) {
    this.Labels = [
      i18n.t("esg.objectives.MITIGATION"),
      i18n.t("esg.objectives.ADAPTATION"),
      i18n.t("esg.objectives.WATER"),
      i18n.t("esg.objectives.CIRCULAR_ECONOMY"),
      i18n.t("esg.objectives.POLLUTION_PREVENTION"),
      i18n.t("esg.objectives.BIODIVERSITY"),
    ];
    return enumToSelectOptions(this.Codes, this.Labels, from, to);
  }

  static codeToLabel(code) {
    this.Labels = [
      i18n.t("esg.objectives.MITIGATION"),
      i18n.t("esg.objectives.ADAPTATION"),
      i18n.t("esg.objectives.WATER"),
      i18n.t("esg.objectives.CIRCULAR_ECONOMY"),
      i18n.t("esg.objectives.POLLUTION_PREVENTION"),
      i18n.t("esg.objectives.BIODIVERSITY"),
    ];
    return enumCodeToLabel(this.Codes, this.Labels, code);
  }

  static codeToDescription(code) {
    this.Description = [
      i18n.t("esg.objectives.MITIGATION-description"),
      i18n.t("esg.objectives.ADAPTATION-description"),
      i18n.t("esg.objectives.WATER-description"),
      i18n.t("esg.objectives.CIRCULAR_ECONOMY-description"),
      i18n.t("esg.objectives.POLLUTION_PREVENTION-description"),
      i18n.t("esg.objectives.BIODIVERSITY-description"),
    ];
    return enumCodeToLabel(this.Codes, this.Description, code);
  }
}

export class EsgStatus {
  static NON_COMPLIANT = 1;
  static COMPLIANT = 2;

  static Codes = [this.NON_COMPLIANT, this.COMPLIANT];

  static Labels = [i18n.t("NON_COMPLIANT"), i18n.t("COMPLIANT")];

  static toSelectOptions(from, to) {
    this.Labels = [i18n.t("NON_COMPLIANT"), i18n.t("COMPLIANT")];
    return enumToSelectOptions(this.Codes, this.Labels, from, to);
  }

  static codeToLabel(code) {
    this.Labels = [i18n.t("NON_COMPLIANT"), i18n.t("COMPLIANT")];
    return enumCodeToLabel(this.Codes, this.Labels, code);
  }
}

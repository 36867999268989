import { ScenarioApi } from "@/api";
import i18n from "@/i18n";
import router from "@/router";
import { showSuccessSnackbar } from "@/utilities/snackbar";
import { JsonUtils } from "@/utilities/jsonUtils";

export async function getSchema() {
  const { data } = await ScenarioApi.getSchema();
  return data;
}

export async function get(id) {
  let data;
  try {
    ({ data: data } = await ScenarioApi.get(id));
  } catch (ex) {
    if (ex.response.status === 404) {
      // TODO: check if it's the correct move
      router.push({ name: "business-plans" });
    }
  }
  return data;
}

export async function getBlobByHash(hash) {
  let data;
  try {
    ({ data: data } = await ScenarioApi.getBlobByHash(hash));
  } catch (ex) {
    if (ex.response.status === 404) {
      // TODO: check if it's the correct move
      router.push({ name: "business-plans" });
    }
  }
  data = JSON.parse(data, JsonUtils.infinityReviver);
  return data;
}

export async function save(scenario) {
  const { data: id } = await ScenarioApi.save(scenario);
  showSuccessSnackbar(200, i18n.t("scenario-saved-correctly"));
  return id;
}

export async function remove(scenarioId) {
  await ScenarioApi.remove(scenarioId);
  showSuccessSnackbar(200, i18n.t("scenario-deleted-correctly"));
}

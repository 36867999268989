<template>
  <v-snackbar
    v-model="snackbar.active"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    bottom
  >
    {{ snackbarMessage }}
    <template #action="{ attrs }">
      <v-icon v-bind="attrs" color="white" @click="onClose"> mdi-close </v-icon>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TheSnackbar",
  computed: {
    ...mapGetters({
      snackbar: "snackbar/getSnackbar",
    }),
    snackbarMessage() {
      return this.snackbar.code && this.snackbar.code !== 200
        ? `${this.snackbar.code} - ${this.snackbar.message}`
        : `${this.snackbar.message}`;
    },
  },
  methods: {
    onClose() {
      this.snackbar.active = false;
    },
  },
};
</script>

<style></style>

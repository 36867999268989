<template>
  <v-app>
    <TheNavbar v-if="showNavbar" />
    <TheHeader v-if="showHeader" />
    <v-main>
      <v-container :fluid="fluid" :class="{ 'fill-height': fillHeight }">
        <router-view />
      </v-container>
    </v-main>
    <TheFullScreenBtn v-if="showFullScreenBtn" />
    <TheFooter v-if="showFooter" />
    <TheSnackbar />
    <TheLoader />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import TheNavbar from "@/components/global/TheNavbar.vue";
import TheHeader from "@/components/global/TheHeader.vue";
import TheSnackbar from "@/components/global/TheSnackbar.vue";
import TheFullScreenBtn from "./components/global/TheFullScreenBtn.vue";
import TheFooter from "./components/global/TheFooter.vue";
import TheLoader from "./components/global/TheLoader.vue";

export default {
  name: "App",
  components: {
    TheNavbar,
    TheHeader,
    TheSnackbar,
    TheFooter,
    TheFullScreenBtn,
    TheLoader,
  },
  computed: {
    ...mapGetters({
      isFullScreen: "shared/getFullScreen",
    }),
    showNavbar() {
      return (!this.isFullScreen && this.$route.meta.navbar?.show) ?? false;
    },
    showHeader() {
      return (!this.isFullScreen && this.$route.meta.header?.show) ?? false;
    },
    showFooter() {
      return this.$route.meta.footer?.show ?? false;
    },
    showFullScreenBtn() {
      return this.$route.meta.fullScreen?.show ?? false;
    },
    fluid() {
      return (this.isFullScreen || this.$route.meta.container?.fluid) ?? false;
    },
    fillHeight() {
      return this.$route.meta.container?.fillHeight ?? false;
    },
  },
};
</script>

<style>
html {
  overflow: auto;
}

.v-application {
  background-color: #f0f0f0 !important;
}

/* Firefox (uncomment to work in Firefox, although other properties will not work!)  */
/** {
  scrollbar-width: thin;
  scrollbar-color: #050D21 #F0F0F0;
}*/

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
*::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #f0f0f0;
}

*::-webkit-scrollbar-track:hover {
  background-color: #d4d4d4;
}

*::-webkit-scrollbar-track:active {
  background-color: #d4d4d4;
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #050d21;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #050d21;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #050d21;
}
</style>

const getDefaultState = () => {
  return {
    data: null,
    loading: false,
    fullScreen: false,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getSharedData: (state) => {
      return state.data;
    },
    getLoading: (state) => {
      return state.loading;
    },
    getFullScreen: (state) => {
      return state.fullScreen;
    },
  },
  mutations: {
    SET_SHARED_DATA(state, data) {
      state.data = data;
    },
    RESET_SHARED_DATA(state) {
      state.data = null;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_FULL_SCREEN(state, payload) {
      state.fullScreen = payload;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    setSharedData({ commit }, data) {
      commit("SET_SHARED_DATA", data);
    },
    resetSharedData({ commit }) {
      commit("RESET_SHARED_DATA");
    },
    setLoading({ commit }, loading) {
      if (!loading) {
        setTimeout(() => {
          commit("SET_LOADING", loading);
        }, 300);
      } else {
        commit("SET_LOADING", loading);
      }
    },
    setFullScreen({ commit }, payload) {
      commit("SET_FULL_SCREEN", payload);
    },
    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};

const getDefaultState = () => {
  return {
    viewName: null,
    search: {
      show: false,
      query: "",
    },
    dropdown: {
      visualization: false,
      scenario: false,
    },
    addons: {
      show: false,
    },
    buttons: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getViewName: (state) => {
      return state.viewName;
    },
    getShowSearch: (state) => {
      return state.search.show;
    },
    getSearchQuery: (state) => {
      return state.search.query;
    },
    getDropdown: (state) => {
      return state.dropdown;
    },
    getButtons: (state) => {
      return state.buttons;
    },
    getShowAddons: (state) => {
      return state.addons.show;
    },
  },
  mutations: {
    SET_VIEW_NAME(state, payload) {
      state.viewName = payload;
    },
    SET_SHOW_SEARCH(state, payload) {
      state.search.show = payload;
    },
    SET_SEARCH_QUERY(state, payload) {
      state.search.query = payload;
    },
    SET_DROPDOWN_VISUALIZATION(state, payload) {
      state.dropdown.visualization = payload;
    },
    SET_DROPDOWN_SCENARIO(state, payload) {
      state.dropdown.scenario = payload;
    },
    SET_BUTTONS(state, payload) {
      state.buttons = payload;
    },
    SET_SHOW_ADDONS(state, payload) {
      state.addons.show = payload;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    setViewName({ commit }, payload) {
      commit("SET_VIEW_NAME", payload);
    },
    showSearch({ commit }, payload) {
      commit("SET_SHOW_SEARCH", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("SET_SEARCH_QUERY", payload);
    },
    showDropdownVisualization({ commit }, payload) {
      commit("SET_DROPDOWN_VISUALIZATION", payload);
    },
    showDropdownScenario({ commit }, payload) {
      commit("SET_DROPDOWN_SCENARIO", payload);
    },
    setButtons({ commit }, payload) {
      commit("SET_BUTTONS", payload);
    },
    showAddons({ commit }, payload) {
      commit("SET_SHOW_ADDONS", payload);
    },
    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};

import Grid from "@/components/grid/grid";
import { BalanceService, BusinessPlanService } from "@/services";

const getDefaultState = () => {
  return {
    workbook: undefined,
    selectedTable: undefined,
    readOnly: true,
    constraints: [],
    addons: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getWorkbook: (state) => {
      return state.workbook;
    },
    getSelectedTable: (state) => {
      return state.selectedTable;
    },
    hasSelectedTableAddons: (state) => {
      if (!state.selectedTable) return false;

      return state.addons.some((t) => t.parent === state.selectedTable.name);
    },
    getAddons: (state) => {
      return state.addons || [];
    },
    isReady: (state) => {
      return state.workbook !== undefined;
    },
    isReadOnly: (state) => {
      return state.readOnly;
    },
    getConstraints: (state) => {
      return state.constraints;
    },
    isWorkBookRespectsConstraints(state) {
      return !state.workbook.tables.some((table) => {
        const items = table.items;
        return state.constraints
          .filter((rule) => rule.table === table.name)
          .some((rule) => {
            const indexRow = table.rows.keys.indexOf(rule.r);
            return rule.c.some((column) => {
              let indexKey = state.workbook.data[column] ?? [];
              indexKey = Array.isArray(indexKey) ? indexKey : [indexKey];
              return indexKey.some((keyCol) => {
                const cell = items[indexRow][keyCol];
                const value = !!Grid.evaluateFormula(
                  rule,
                  state.workbook,
                  cell.ci,
                  cell.ri
                );
                return !value;
              });
            });
          });
      });
    },
  },
  mutations: {
    SET_WORKBOOK(state, workbook) {
      state.workbook = workbook;
    },
    SET_SELECTED_TABLE(state, selectedTable) {
      state.selectedTable = selectedTable;
    },
    SET_READ_ONLY(state, readOnly) {
      state.readOnly = readOnly;
    },
    SET_CONSTRAINTS(state, constraints) {
      state.constraints = constraints;
    },
    SET_ADDONS(state, addons) {
      state.addons = addons;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    setWorkbook({ commit }, payload) {
      commit("SET_WORKBOOK", payload);
    },
    setSelectedTable({ commit }, payload) {
      commit("SET_SELECTED_TABLE", payload);
    },
    setReadOnly({ commit }, payload) {
      commit("SET_READ_ONLY", payload);
    },
    async fetchConstraints({ commit }, type) {
      const fetchService =
        type === "balances" ? BalanceService : BusinessPlanService; // TODO: Not best implementation
      const data = await fetchService.getConstraints();
      commit("SET_CONSTRAINTS", data.constraints);
    },
    async fetchAddons({ commit }, type) {
      const fetchService =
        type === "balances" ? BalanceService : BusinessPlanService; // TODO: Not best implementation
      const data = await fetchService.getAddons();
      commit("SET_ADDONS", data.addons);
    },
    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};

<template>
  <div>
    <v-tooltip right>
      <template #activator="{ on, attrs }">
        <v-btn
          small
          icon
          color="white"
          v-bind="attrs"
          v-on="on"
          @click="handleClose"
        >
          <v-icon small> mdi-exit-to-app </v-icon>
        </v-btn>
      </template>
      <span>{{ $t("back-to-administration") }}</span>
    </v-tooltip>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="error white--text">
          {{ $t("exit-confirm") }}
        </v-card-title>
        <v-card-text class="pt-5">
          {{ $t("back-to-administration-warning") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn plain @click="dialog = false">{{ $t("close") }}</v-btn>
          <v-btn color="error" dark @click="onConfirm">{{
            $t("confirm")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  emits: ["confirm"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    handleClose() {
      this.dialog = true;
    },
    onConfirm() {
      this.dialog = false;
      this.$emit("confirm");
    },
  },
};
</script>

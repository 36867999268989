import { axiosClient } from "../axios";

const baseUrl = "api/esg/enterprise-not-applicable-eligibility-tests";

export function get(enterpriseId) {
  return axiosClient.get(`${baseUrl}/enterprise/${enterpriseId}`);
}

export function save(esgEligibilityTestId, enterpriseId, isNotApplicable) {
  return axiosClient.post(
    `${baseUrl}/esg-eligibility-test/${esgEligibilityTestId}/enterprise/${enterpriseId}/save`,
    isNotApplicable
  );
}
